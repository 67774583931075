<template>
  <v-sheet 
    ref="container"
    class="map-container pa-0"
    @mousemove="positionInfoCard"
  >
    <GmapMap
      :center="map.center"
      :zoom="map.zoom"
      :options="map.options"
      class="map"
      ref="map"
      @zoom_changed="changedProp('zoom', $event)"
      @center_changed="changedProp('center', $event)"
      @drag="panned(true)"
    >
      <gmap-cluster
        v-if="map.layers.buzzers"
        :zoom-on-click="true"
        :styles="map.cluster.styles"
        :max-zoom="map.cluster.maxZoom"
      >
        <GmapMarker
          v-for="m in trackedBuzzers"
          :key="m.code"
          :position="m.impression.position"
          :clickable="true"
          :icon="setBuzzerIcon(m)"
          @click="selectBuzzer(m.code)"
        />
      </gmap-cluster>

      <gmap-cluster
        v-if="pois.toggle"
        :zoom-on-click="true"
        :styles="map.cluster.styles"
        :max-zoom="10"
      >
        <GmapMarker
          v-for="p in points"
          :key="'poi-'+p.id"
          :position="p.position"
          :opacity="metric==null ? p.opacity : p.opacity[metric]"
          :clickable="true"
          :icon="map.icons.poi"
          @mouseover="poiMouseOver(p, $event)"
          @mouseout="poiMouseOut(p, $event)"
        />
      </gmap-cluster>

      <!-- <gmap-drawing-manager
        v-if="map.layers.pois"
        :circle-options="circleOptions"
        :shapes="shapes"
      /> -->
    </GmapMap>

    <v-sheet
      v-if="controls"
      color="transparent"
      class="map-controls d-flex flex-column"
    >
      <v-btn
        fab
        small
        elevation="1"
        class="grey--text text--darken-2 mb-4"
        @click="map.zoom += 1"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        elevation="1"
        class="grey--text text--darken-2"
        @click="map.zoom -= 1"
      >
        <v-icon>{{ icons.mdiMinus }}</v-icon>
      </v-btn>
    </v-sheet>

    <v-fade-transition>
      <v-card
        v-show="map.info.toggle"
        class="info-window"
        ref="info"
        flat
        max-width="320px"
        :style="{ top: map.info.top + 'px', left: map.info.left + 'px' }"
      >
        <v-card-title class="title text-subtitle-1 font-weight-bold grey--text text--darken-2 d-block text-truncate">
          <span class="text-uppercase">{{ map.info.title }}</span> <br> 
          <span class="text-subtitle-2 text--disabled">{{ map.info.city }}</span>
        </v-card-title>
        <v-card-text 
          v-if="metric!=null&&(geofences.toggle||pois.toggle)"
          class="pb-2 px-6"
        >
          <div 
            v-for="(info, m) in metrics"
            :key="`info-${m}`"
            class="text-body-1 font-weight-medium mb-4"
            :class="{ 'primary--text': metric==m }"
          >
            <v-icon 
              size="20"
              :color="metric==m ? 'primary' : 'grey'"
            >
              {{ icons[m] }}
            </v-icon>
            <b 
              class="ma-4 pt-1 font-weight-medium"
              :class="{ 'font-weight-black': metric==m }"
            >{{ info.value | numeral(m=='spent'||m=='cpm' ? '$ 0,0[.]0[0] a' : '0,0[.]0[0] a') }}</b>
          </div>
        </v-card-text>
      </v-card>
    </v-fade-transition>

    <v-card
      v-if="metric!=null&&(map.layers.geofences||pois.toggle)"
      elevation="1"
      class="legend"
    >
      <!-- <v-card-title class="subtitle-2 grey--text">
        {{ map.legend.title }}
      </v-card-title> -->
      <v-card-text class="py-2">
        <v-container 
          fluid 
          class="pa-0"
        >
          <v-row no-gutters>
            <v-col 
              align-self="center"
              class="text-left"
            >
              <span class="caption">
                {{ map.legend.min | numeral('0,0 a') }}
              </span>
            </v-col>
            <v-col
              v-for="(step, k) in map.legend.steps"
              :key="'tile-'+k"
              class="step"
              align-self="center"
            >
              <v-tooltip 
                top
                color="primary lighten-1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-sheet
                    height="8"
                    tile
                    :style="calcLegendIntensity(step)"
                    class="tile"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ step | numeral('0,0 a') }}</span>
              </v-tooltip>
            </v-col>
            <v-col 
              align-self="center"
              class="pl-2 text-right"
            >
              <span class="caption">
                {{ map.legend.max | numeral('0,0 a') }}
              </span>
            </v-col>
          </v-row>
          <!-- <v-row no-gutters>
            <v-col
              v-for="(step, k) in map.legend.steps"
              :key="'label-'+k"
              class="step text-center grey--text"
            >
              <span 
                v-if="k!=1&&k!=3"
                class="caption"
              >
                {{ step | numeral('0,0 a') }}
              </span>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
      <!-- <v-slider
        v-model="map.filter.interval"
        inverse-label
        :max="map.filter.max"
        :min="map.filter.min"
        hide-details
        class="pa-2 pt-0 pb-0 pr-4 mb-n2"
        :prepend-icon="map.filter.player.controller == null ? icons.mdiPlay : icons.mdiStop"
        @click:prepend="playFilter"
        @click="map.filter.player.count = 10"
      /> -->
    </v-card>
  </v-sheet>
</template>

<style type="text/css">

  .map-container, .map {
    position: relative;
    width: 100%; 
    height: 100%;
    overflow: hidden;
  }
  @media (max-width: 600px) and (orientation: landscape) {
    .map-container .map {
      border-radius: 0;
    }
  }

  .map .vue-map > div {
    background: white !important;
  }

  .map-container {
    /* box-shadow: var(--inset-2); */
    /* border: thin solid rgba(0, 0, 0, 0.08) !important; */
  }

  .map-container .filter {
    position: absolute;
    top: 20px;
    left: 20px;
    opacity: .85;
  }

  .map-container .refresh-btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 11;
    opacity: .85;
  }

  .map-container .info-window {
    position: fixed;
    top: 0;
    left: 0;
    opacity: .95;
    min-width: 180px;
    z-index: 5;
    pointer-events: none;
    filter: drop-shadow(0px 4px 12px rgba(61, 75, 143, 0.16));
  }
  .map-container .info-window .title {
    letter-spacing: .05rem !important;
    line-height: 1.5rem;
  }

  .map-container .heatmap-btn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    z-index: 11;
    opacity: .85;
  }

  .map-container .legend {
    position: absolute;
    min-width: 240px;
    bottom: .5rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    opacity: .85;
  }
  .map-container .legend .caption {
    display: inline-block;
    line-height: 1 !important;
  }
  .map-container .legend .tile {
    position: relative;
    border: 2px solid rgba(71, 108, 255, 0.08);
    margin: 0;
    will-change: border, box-shadow;
    transition: box-shadow .125s ease, border .125s ease;
    /* box-shadow: 0 0 0 0 #000; */
  }
  .map-container .legend .tile:hover {
    border: 2px solid rgba(71, 108, 255, 0.24);
    z-index: 200;
    /* box-shadow: 0 0 8px 6px #000; */
  }

  .gm-control-active img {
    width: 12px !important;
    height: 12px !important;
  }

  .map-container .map-controls {
    position: absolute;
    bottom: 28px;
    right: 16px;
  }

  .map-container .cluster span {
    display: inline-block;
    padding-top: 14px;
    padding-left: 4px;
  }


</style>

<script>
  // Icons
  import { mdiAccount, mdiClock, mdiCurrencyUsd, mdiImage, mdiMagnifyPlusOutline, mdiPlus, mdiMinus } from '@mdi/js';

  // Utilities
  import services, { icons } from '@/services'
  import { sync } from 'vuex-pathify'
  import maps from '@/maps.js'
  import { gmapApi } from 'gmap-vue'
  import * as easings from 'vuetify/es5/services/goto/easing-patterns'
  import _ from 'lodash';
  import device from 'mobile-device-detect';
  import axios from 'axios'
  // import visibility from 'vue-visibility-change';
  var moment = require('moment');

  const bai = () => import('@/assets/data/rj-rj.json');
  const zon = () => import('@/assets/data/rj-rj-bairro-zona.json');

  export default {
    name: 'MapComp',

    props: {
      heatmap: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            options: {},
          }
        }
      },
      buzzers: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            selected: null,
            alert: false,
            options: {},
          }
        }
      },
      geofences: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: {},
            options: {},
          }
        }
      },
      pois: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            options: {},
          }
        }
      },
      outline: {
        type: Object,
        default: () => {
          return {
            toggle: false,
            data: [],
            style: {},
          }
        }
      },
      options: {
        type: Object,
        default: () => {}
      },
      metric: {
        type: String,
        default: null,
      },
      layers: {
        type: Array,
        default: () => ['buzzers']
      },
      roles: {
        type: Array,
        default: () => [],
      },
      legend: {
        type: Boolean,
        default: true
      },
      controls: {
        type: Boolean,
        default: true
      }
    },

    data: () => ({
      icons: {
        mdiMagnifyPlusOutline,
        mdiPlus,
        mdiMinus,
        audience: mdiAccount,
        impressions: mdiImage,
        spent: mdiCurrencyUsd,
        cpm: icons.cpm,
        airtime: mdiClock,
      },
      device: device.isMobileOnly,
      map: {
        ref: null,
        ready: false,
        heatmap: {
          loaded: false,
          ref: null,
        },
        layers: {
          heatmap: false,
          buzzers: false,
          geofences: false,
          outline: false,
          pois: false
        },
        events: {
          over: null,
          out: null,
          click: null
        },
        geofences: {
          data: {},
          loader: {
            pending: {},
            loading: false,
            map: null
          },
          source: {
            BAI: {}, 
            ZON: {},
          },
          features: {},
          display: [],
        },
        outline: {
          data: {},
          loader: {
            pending: {},
            loading: false,
            map: null
          },
          available: {},
          source: {},
          features: {},
          display: [],
          info: {
            toggle: false,
            top: 0,
            left: 0,
            title: '',
          }
        },
        pois: {
          data: {},
          loader: {
            pending: {},
            loading: false,
            map: null
          },
          source: {},
          features: {},
          points: {},
        },
        info: {
          toggle: false,
          top: 0,
          left: 0,
          title: '',
          metrics: {
            audience: {
              value: null,
            },
            impressions: {
              value: null,
            },
            spent: {
              value: null,
              roles: [1,6,7]
            },
            cpm: {
              value: null,
              roles: [1,6,7]
            },
            airtime: {
              value: null,
            },
          }
        },
        legend: {
          title: 'Mapa de Impactos',
          steps: [],
          range: 5,
          min: 0,
          max: 0,
        },
        radius: 30,
        center: {
          lat:-22.9548645, lng:-43.189667
          // lat: 37, lng: -122
        },
        zoom: 12,
        padding: 36,
        options: {
          ...maps.light,
          maxZoom: 17,
          disableDefaultUI: true
        },
        icons: {
          poi: {
            url: "/img/poi-marker.svg",
            size: { width: 24, height: 24, f: 'px', b: 'px' },
            anchor: { x: 12, y: 12 }
          },
          buzzer: {
            url: "/img/buzzer-marker.svg",
            size: { width: 32, height: 32, f: 'px', b: 'px' },
            anchor: { x: 16, y: 16 }
          },
        },
        cluster: {
          styles: [
            {
              textColor: 'white',
              url: '/img/poi-cluster.png',
              height: 40,
              width: 40
            },
          ],
          maxZoom: 12
        },
        btn: {
          disabled: false,
          loading: false
        },
        controls: {
          zoom: false
        }
      },
    }),

    computed: {
      views: sync('app/views'),
      loading: sync('app/views@loading'),
      view: sync('app/views@map'),
      user: sync('user/data'),
      toast: sync('app/toast'),

      google: gmapApi,

      // heatmapMax () {
      //   const filter = this.map.filter;
      //   const data = this.heatmap.data[_.find(filter.steps, { doy: filter.interval }).date];

      //   const maxPerDay = _.map(data, (group) => {
      //     return _.reduce(group, (max, point) => {
      //       return point.audience + max;
      //     },0);
      //   }).sort()[0];
      //   const max = _.reduce(data, (max, point) => {
      //     return point.audience > max ? point.audience : max;
      //   },0);
      //   const count = this.map.data.serve.length;
      //   const total = _.reduce(this.map.data.serve, (total, point) => {
      //     return point.audience + total;
      //   },0);
      //   const avg = total / count;
      //   // console.log(max, maxPerDay, avg);
      //   return avg;
      // },

      metrics () {
        const role = _.last(this.roles);
        return _.pickBy(this.map.info.metrics, d => !_.has(d, 'roles') || _.indexOf(d.roles, role)>=0);
      },

      points () {
        return _.values(this.map.pois.points);
      },

      filterRangeText () {
        const steps = this.map.filter.steps;
        return this.map.filter.steps.length > 0 ? moment(_.find(steps, { 'doy': this.map.filter.interval }).date, 'DD/MM/YY').format('DD/MM') : '';
      },

      trackedBuzzers () {
        const impression = this.buzzers.impression;
        return _.pickBy(this.buzzers.data, (item) => {
          const position = impression ? item.impression.position : item.position;
          return position.lat !== 0 && position.lat !== null;
        });
      },

      auditor () {
        return _.some(this.user.roles, ['id_perfil', 9]);
      }

    },

    watch: {
      pois: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log('POI', get);
          if (get.toggle) {
            this.$nextTick(() => {
              if (this.map.ready) {
                this.initPoiLayer();
              }
            });
          }else{
            // clear layer data
            this.clearLayer('pois');
          }
          this.map.layers.pois = get.toggle;
        }
      },
      outline: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log(get);
          if (get.toggle) {
            this.$nextTick(() => {
              if (this.map.ready) {
                this.initOutlineLayer();
              }
            });
          }else{
            // clear layer data
            this.clearLayer('outline');
          }
          this.map.layers.outline = get.toggle;
        }
      },
      geofences: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log(get);
          if (get.toggle&&!_.isEmpty(get.data)) {
            this.$nextTick(() => {
              if (this.map.ready) {
                this.initGeofenceLayer();
              }
            });
          }else{
            // clear layer data
            this.clearLayer('geofences');
          }
          this.map.layers.geofences = get.toggle;
        }
      },
      metric: {
        handler (m) {
          // console.log(m);
          this.$nextTick(() => {
            if (this.map.layers.geofences&&this.map.ready) {
              this.initGeofenceLayer();
            }else if (this.map.layers.pois&&this.map.ready) {
              this.initPoiLayer();
            }
          });
        }
      },
      buzzers: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log(get);
          if (get.toggle) {
            if (get.selected!=null) {
              // console.log(get.selected);
              this.getBounds();
            }
          }else{
          }
          this.map.layers.buzzers = get.toggle;
        }
      },
      heatmap: {
        immediate: true,
        deep: true,
        handler (get) {
          // console.log(get);
          if (get.toggle) {
            this.$refs.map.$mapPromise.then((map) => {
              if (this.map.heatmap.ref!=null) {
                this.map.heatmap.ref.then(heatmap => heatmap.setMap(map));
                this.getBounds();
              }else{
                this.map.heatmap.ref = this.initHeatmap();
              }
            });
          }else{
            // this.map.heatmap.ref.then(heatmap => heatmap.setMap(null));
          }
          this.map.layers.heatmap = get.toggle;
        }
      },

      options: {
        immediate: true,
        deep: true,
        handler (options) {
          this.map.options = _.merge(this.map.options, options);
        }
      },

      'map.geofences.loader.pending': {
        handler (pending) {
          if (!_.isEmpty(pending)) { 
            if (!this.map.geofences.loader.loading) {
              this.geojsonLoader('geofences', this.map.ref);
            }
          }else if (!_.isEmpty(this.map.geofences.features)) {
            console.log('get geofences bounds');
            this.mapBounds('geofences');
          }
        }
      },
      'map.pois.loader.pending': {
        handler (pending) {
          if (!_.isEmpty(pending)) { 
            if (!this.map.pois.loader.loading) {
              this.geojsonLoader('pois', this.map.ref);
            }
          }else if (!_.isEmpty(this.map.pois.features)) {
            console.log('get geofences bounds');
            this.mapBounds('pois');
          }
        }
      },

      // $route (to, from) {
      //   if (this.map.ready) this.updateView(to);
      // }
    },

    methods: {
      ...services,

      changedProp (prop, e) {
        if (prop=='zoom') this.map[prop] = e;
      },

      panned (b) {
        this.view.panned = b;
      },

      setLegend () {
        let legend =  this.map.legend;
        legend.steps = _.concat([legend.min], _.map([...Array(legend.range-2).keys()], (r) => {
          return _.round((legend.max / (legend.range+2)) * (r+1));
        }), [legend.max]);
      },

      async initPoiLayer () {
        console.log('initPoiLayer...');
        const $ = this;
        const data = this.pois.data;
        if (!_.isNil($.metric)) {  
          const metric = $.metric;
          const s = _.toArray(data);
          _.each(['audience', 'impressions', 'spent', 'cpm', 'airtime'], m => {
            if (_.some(data, (d) => _.has(d, m))) {
              const l = {
                min: _.minBy(s, m)[m],
                max: _.maxBy(s, m)[m],
              }
              $.map.legend[m] = l;
            }
          });
          $.map.legend.min = $.map.legend[metric].min;
          $.map.legend.max = $.map.legend[metric].max;
        }

        if ($.pois.point) {
          return $.$refs.map.$mapPromise.then((map) => {
            const style = _.has(data, 'style') ? style : {
              fillColor: '#698DF2',
              fillOpacity: .24,
              strokeColor: '#698DF2',
              strokeOpacity: .48,
              strokeWeight: 1,
            };

            const points = _.keyBy($.pois.data, 'id');
            $.map.pois.data = _.clone(points);
            const features = $.map.pois.points;
            const updated = _.difference(_.keys(points), _.keys(features)).length>0;
            if (!updated) {
              if (_.has($.pois, 'zoom')&&!_.isEmpty($.pois.zoom)) $.mapBounds('pois', $.pois.zoom);
              return;
            }
            $.clearLayer('pois');

            _.each(points, (p, i) => {
              if (_.has(p, 'position')) {
                p.id = _.has(p, 'id') ? p.id : i;
                const drawing = new google.maps.Circle({
                  ...style,
                  map,
                  center: p.position,
                  radius: _.has(p, 'radius') ? p.radius : 1000,
                });
                $.$set($.map.pois.points, p.id, {
                  drawing,
                  opacity: _.isNil($.metric) ? .8 : _.mapValues({'audience': null, 'impressions': null, 'spent': null, 'cpm': null, 'airtime': null}, (v,m) => {
                    return $.setPointStyle(p[m], m, style).fillOpacity;
                  }),
                  ...p
                });
              // }, 8*i, p, $);
              // console.log(drawing);
              }
            });
            
            $.setLegend();

            $.mapBounds('pois');
          });
        }else{
          return this.$refs.map.$mapPromise.then((map) => {
            map.data.setStyle($.setPoiStyle);

            const geofences = _.keyBy($.pois.data, 'id');
            $.map.pois.data = _.clone(geofences);
            const features = $.map.pois.features;
            const updated = _.difference(_.keys(geofences), _.keys(features)).length>0;
            if (!updated) {
              if (_.has($.pois, 'zoom')&&!_.isEmpty($.pois.zoom)) $.mapBounds('pois', $.pois.zoom);
              return;
            }

            $.google.maps.event.removeListener($.map.events.over);
            $.map.events.over = map.data.addListener("mouseover", (event) => {
              map.data.revertStyle();
              map.data.overrideStyle(event.feature, { 
                strokeWeight: 2,
                // fillOpacity: .4,
                strokeOpacity: .24,
              });
              const id = event.feature.getProperty('id');
              $.map.info.title = $.map.pois.data[id]['title'];
              $.map.info.city = $.map.pois.data[id]['city']['title'];
              if (!_.isNil(this.metric)) {
                $.map.info.metrics.audience.value = $.map.pois.data[id]['audience'];
                $.map.info.metrics.impressions.value = $.map.pois.data[id]['impressions'];
                $.map.info.metrics.spent.value = $.map.pois.data[id]['spent'];
                $.map.info.metrics.cpm.value = $.map.pois.data[id]['cpm'];
                $.map.info.metrics.airtime.value = $.map.pois.data[id]['airtime'];
              }
              $.map.info.toggle = true;
            });

            $.map.events.out = map.data.addListener("mouseout", (event) => {
              map.data.revertStyle();
              $.map.info.toggle = false;
            });

            let pending = {};
            console.log(geofences);

            map.data.forEach(function(feature) {
              const id = feature.getProperty('id');
              if (!_.has(geofences, id)) {
                console.log('removing', id);
                $.$delete($.map.pois.features, id);
                map.data.remove(feature);
              }
            });

            let i=0;
            _.each(geofences, (g, k) => {
              if (_.has($.map.pois.source, g.id)) {
                $.addGeojson('pois', $.map.pois.source[g.id], g.id, g, map.data);
              }else{
                pending[g.id] = g;
              }
            });

            if ($.map.ref==null) $.map.ref = map.data;
            if (!_.isEmpty(pending)) {
              $.map.pois.loader.pending = Object.assign({}, pending);
            }else{
              console.log('pois bounds');
              $.mapBounds('pois');
            }

            $.setLegend();

          });
        }
      },

      setPoiStyle (feature) {
        const style = _.has(this.pois, 'style') ? this.pois.style : {
          fillColor: '#698DF2',
          strokeColor: '#698DF2',
          strokeOpacity: .08,
          strokeWeight: 1,
        };

        const min = this.map.legend.min;
        const max = this.map.legend.max;
        const id = feature.getProperty('id');
        let fraction = 0;
        if (_.has(this.map.pois.data, id)&&!_.isNil(this.metric)) {
          const metric = this.map.pois.data[id][this.metric];
  
          const low = [224,80,64];   // color of mag 1.0
          const high = [14,100,72];  // color of mag 6.0 and above
  
          // fraction represents where the value sits between the min and max
          fraction = (metric / max);
  
          const color = this.interpolateHsl(low, high, fraction);
        }

        // console.log(color, fraction);

        return {
          fillColor: style.fillColor, //color
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight
        }
      },

      clearLayer (layer) {
        if (layer=='pois') {
          let points = this.map.pois.points;
          if (_.size(points)>0) {
            _.each(points, d => {
              d.drawing.setMap(null);
            });
            this.map.pois.points = {};
          }
          if (!_.isNil(this.map.ref)) {
            _.each(this.map.pois.features, feature => {
              this.map.ref.remove(feature);
            });
            this.map.ref.setStyle({});
          }
        }else if (layer=='geofences'||layer=='outline') {
          if (!_.isNil(this.map.ref)) {
            _.each(this.map[layer].features, feature => {
              this.map.ref.remove(feature);
            });
            this.map.ref.setStyle({});
          }
        }
        this.map[layer].features = {};
        this.map[layer].loader.pending = {};
      },
      
      poiMouseOver (point, event) {
        console.log(point.drawing);
        point.drawing.setOptions({ 
          fillOpacity: _.isNil(this.metric) ? .48 : (point.opacity[this.metric] * .32) + .02,
          strokeWeight: 2,
          // strokeOpacity: .32,
        });
        this.map.info.title = point.title;
        this.map.info.metrics = _.isNil(this.metric) ? null : _.mapValues(this.map.info.metrics, (m, k) => {
          m.value = point[k];
          return m;
        });
        this.map.info.toggle = true;
      },

      poiMouseOut (point, event) {
        _.each(this.map.pois.points, p => {
          p.drawing.setOptions({ 
            fillOpacity: .24,
            strokeWeight: 1,
          });
        })
        point.drawing.setOptions({ 
          fillOpacity: .24,
          strokeWeight: 2,
        });
        this.map.info.toggle = false;
      },

      setPointStyle (value, metric, style) {

        const min = this.map.legend[metric].min;
        const max = this.map.legend[metric].max;

        const low = [178,56,50];   // color of mag 1.0
        const high = [354,100,72];  // color of mag 6.0 and above

        // fraction represents where the value sits between the min and max
        const fraction = (value / max);

        const color = this.interpolateHsl(low, high, fraction);

        // console.log(color, fraction);

        return {
          fillColor: style.fillColor,
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight
        }
      },

      initOutlineLayer () {
        console.log('initOutlineLayer...');
        const $ = this;
        const data = this.outline.data;
        const selectable = _.has(this.outline, 'selectable') && this.outline.selectable;
        return this.$refs.map.$mapPromise.then((map) => {
          map.data.setStyle(this.setOutlineStyle);

          const geofences = _.keyBy(data, 'id');
          $.map.outline.data = _.clone(geofences);
          const features = $.map.outline.features;
          const updated = _.difference(_.keys(geofences), _.keys(features)).length>0;
          if (!updated) {
            if (_.has(this.outline, 'zoom')&&!_.isEmpty($.outline.zoom)) this.mapBounds('outline', this.outline.zoom);
            return;
          }

          $.google.maps.event.removeListener($.map.events.over);
          $.google.maps.event.removeListener($.map.events.click);
          if (selectable) {
            $.map.events.click = map.data.addListener("click", (event) => {
              const id = event.feature.getProperty('id');
              const selected = $.map.outline.data[id]['selected'];
              console.log('select', id, !selected);
              $.selectGeofence('outline', id, !selected);
            });
          }
          $.map.events.over = map.data.addListener("mouseover", (event) => {
            map.data.revertStyle();
            const id = event.feature.getProperty('id');
            const selected = $.map.outline.data[id]['selected'];
            map.data.overrideStyle(event.feature, { 
              strokeWeight: selected ? 1 : 2,
              fillOpacity: selected ? .24 : .64,
              strokeColor: selected ? '#FFF' : '#698DF2',
              strokeOpacity: .48,
            });
            $.map.info.title = $.map.outline.data[id]['title'];
            $.map.info.city = $.map.outline.data[id]['city']['title'];
            $.map.info.toggle = true;
          });

          this.map.events.out = map.data.addListener("mouseout", (event) => {
            map.data.revertStyle();
            this.map.info.toggle = false;
          });

          const source = _.assign({}, this.map.geofences.source['BAI'], this.map.geofences.source['ZON']);
          // console.log(geofences, source);

          if (!_.isNil(this.map.ref)) {
            _.each(this.map.outline.features, feature => {
              this.map.ref.remove(feature);
            });
            this.map.outline.features = {}
          }

          _.each(geofences, (g) => {
            const k = _.find(source, s => {
              return s.id == g.id;
            })
            if (!_.isNil(k)) {
              let geojson = k.geojson;
              geojson = _.isString(geojson) ? JSON.parse(geojson) : geojson;
              geojson.features[0].properties.id = g.id;
              geojson.features[0].properties.selected = !selectable || _.has(g, 'selected') && g.selected;
              geojson.features[0].properties.title = g.title;
              geojson.features[0].properties.city = _.has(g, 'city') ? g.city.title : 'Rio de Janeiro';
              const feature = map.data.addGeoJson(geojson, { id: g.id })[0];
              this.map.outline.features[g.id] = feature;
            }
          });
          if ($.map.ref==null) $.map.ref = map.data;

          this.mapBounds('outline');
        });
      },

      setOutlineStyle (feature) {
        const id = feature.getProperty('id');
        const selected = !_.has(this.map.outline.data[id], 'selected') || this.map.outline.data[id]['selected'];
        return _.has(this.outline, 'style') ? this.outline.style : {
          fillColor: '#698DF2',
          fillOpacity: selected ? .48 : .16,
          strokeColor: '#698DF2',
          strokeOpacity: .08,
          strokeWeight: selected ? 2 : 1,
        };
      },

      selectGeofence (layer, id, select) {
        this.map[layer].data[id].selected = select;
        const zone = _.has(this.map[layer].data[id], 'geofences');
        const geofences = zone ? 
          _.reduce(_.pickBy(this.map[layer].data, g => g.selected), (selected, zone) => {
            return _.concat(selected, _.map(zone.geofences, g => g.id))
          }, []) :
          _.map(_.pickBy(this.map[layer].data, g => g.selected), g => g.id);
        console.log(geofences);
        this.$emit('update', geofences);
      },

      initGeofenceLayer () {
        console.log('initGeofenceLayer...');

        const $ = this;
        const data = this.geofences.data;
        const type = this.geofences.type;
        const metric = this.metric;
        if (_.some(data, (d) => _.has(d, metric))) {
          const s = _.toArray(data);
          this.map.legend.min = _.minBy(s, metric)[metric];
          this.map.legend.max = _.maxBy(s, metric)[metric];
          // if (metric=='cpm') {
          //   const min = this.map.legend.max;
          //   const max = this.map.legend.min;
          //   this.map.legend.min = min;
          //   this.map.legend.max = max;
          // }
        }
        return this.$refs.map.$mapPromise.then(async (map) => {
          map.data.setStyle(this.setGeofenceStyle);

          const geofences = this.geofences.data;
          this.map.geofences.data = _.clone(geofences);
          const features = this.map.geofences.features;
          const updated = _.difference(_.keys(geofences), _.keys(features)).length>0;
          if (!updated) {
            if (_.has(this.geofences, 'zoom')&&!_.isEmpty(this.geofences.zoom)) this.mapBounds('geofences', this.geofences.zoom);
            return;
          }

          this.google.maps.event.removeListener(this.map.events.over);
          this.map.events.over = map.data.addListener("mouseover", (event) => {
            map.data.revertStyle();
            map.data.overrideStyle(event.feature, { 
              strokeWeight: 2,
              // fillOpacity: .4,
              strokeOpacity: .36,
            });
            const id = event.feature.getProperty('id');
            // console.log(id);
            this.map.info.title = this.map.geofences.data[id]['title'];
            this.map.info.city = this.map.geofences.data[id]['city']['title'];
            this.map.info.metrics.audience.value = this.map.geofences.data[id]['audience'];
            this.map.info.metrics.impressions.value = this.map.geofences.data[id]['impressions'];
            this.map.info.metrics.spent.value = this.map.geofences.data[id]['spent'];
            this.map.info.metrics.cpm.value = this.map.geofences.data[id]['cpm'];
            this.map.info.metrics.airtime.value = this.map.geofences.data[id]['airtime'];
            this.map.info.toggle = true;
          });

          this.map.events.out = map.data.addListener("mouseout", (event) => {
            map.data.revertStyle();
            this.map.info.toggle = false;
          });

          const source = this.map.geofences.source[type];
          let pending = [];
          // console.log(geofences, source);

          map.data.forEach(function(feature) {
            const id = feature.getProperty('id');
            if (!_.has(geofences, id)) {
              console.log('removing', id);
              $.$delete($.map.geofences.features, id);
              map.data.remove(feature);
            }
          });

          let i=0;
          // _.each(geofences, (g, k) => {
          for (const k in geofences) {
            const g = geofences[k];
            if (!_.isNil(g)&&_.has(source, k)) {
              i+=1;
              let geojson = source[k].geojson;
              geojson = _.isString(geojson) ? JSON.parse(geojson) : geojson;

              this.addGeojson('geofences', geojson, k, g, map.data);
              // setTimeout(this.addGeojson, 16*i, 'geofences', geojson, k, g, map.data);
            }else{
              if (_.has(g, 'url')&&_.has(g, 'city')&&!_.isNil(g.city)) {
                pending.push(g);
              }
            }
          }

          if (this.map.ref==null) this.map.ref = map.data;
          if (!_.isEmpty(pending)) {
            this.map.geofences.loader.pending = _.groupBy(pending, p => p.city.id);
          }else{
            console.log('get geofences bounds');
            this.mapBounds('geofences');
          }

          let legend =  this.map.legend;
          legend.steps = _.concat([legend.min], _.map([...Array(legend.range-2).keys()], (r) => {
            return _.round((legend.max / (legend.range+2)) * (r+1));
          }), [legend.max]);


        });
      },

      geojsonLoader (layer, map) {
        if (_.isNil(map)) map = this.map.ref;
        if (_.isEmpty(this.map[layer].loader.pending)) {
          return;
        }

        this.map[layer].loader.loading = true;

        const g = _.sample(this.map[layer].loader.pending);

        console.log(g.id, 'loading...');
        axios(g.url)
        .then((response) => {
          console.log(g.id, 'loaded');
          const geojson = this.formatGeojson(response.data);
          this.addGeojson(layer, geojson, g.id, g, map);
          this.$set(this.map[layer].source, g.id, geojson);
          this.$delete(this.map[layer].loader.pending, g.id);

          this.map[layer].loader.loading = false;
          this.geojsonLoader(layer, map);
        }, (error) => {
          console.log(g.id, error);
          setTimeout(($) => {
            $.geojsonLoader(layer, map);
          }, 1000, this);
        });
      },

      addGeojson (layer, geojson, k, g, data) {
        // console.log(k);
        const hasCity = (_.has(g, 'city')&&!_.isNil(g.city));
        let feature = null;
        if (_.has(this.map[layer].features, k)) { 
          feature = this.map[layer].features[k];
        }else{
          feature = data.addGeoJson(geojson)[0];
          this.$set(this.map[layer].features, k, feature);
        }
        feature.setProperty('id', k);
        // console.log(p[0].getProperty('title'));
      },

      formatGeojson (geojson) {
        if (!_.has(geojson, 'Feature')&&!_.has(geojson, 'FeatureCollection')) geojson = { type: 'Feature', geometry: geojson }
        return geojson;
      },

      setGeofenceStyle (feature) {
        const style = _.has(this.geofences, 'style') ? this.geofences.style : {
          fillColor: '#698DF2',
          strokeColor: '#698DF2',
          strokeOpacity: .16,
          strokeWeight: 1,
        };

        const min = this.map.legend.min;
        const max = this.map.legend.max;
        const id = feature.getProperty('id');
        let fraction = 0;
        if (_.has(this.map.geofences.data, id)) {
          const metric = this.map.geofences.data[id][this.metric];
          const low = [224,80,64];   // color of mag 1.0
          const high = [14,100,72];  // color of mag 6.0 and above
          // fraction represents where the value sits between the min and max
          fraction = (metric / max);
          const color = this.interpolateHsl(low, high, fraction);
          // console.log(color, fraction);
        }
        return {
          fillColor: style.fillColor, //color
          fillOpacity: (fraction * .48) + .32,
          strokeColor: style.strokeColor,
          strokeOpacity: style.strokeOpacity,
          strokeWeight: style.strokeWeight
        }
      },

      positionInfoCard (e) {
        if (this.map.info.toggle) {
          const spacing = { top: -24, left: 48 };
          const margin = 4;
          const map = this.$refs.container.$el.getBoundingClientRect();
          const info = this.$refs.info.$el.getBoundingClientRect();
          let top = e.clientY + spacing.top;
          let left = e.clientX + spacing.left;
          const bottom = info.height + top;
          const right = info.width + left;

          if (bottom>=map.height+map.top-margin) {
            top -= bottom - (map.height + map.top) + margin;
          }else if (top<=margin+map.top) {
            top = map.top + margin;
          }
          if (right>=map.width+map.left-margin) {
            left -= (2 * spacing.left) + info.width;
          }
          // console.log(top, map.top, info.height);
          this.map.info.top = top;
          this.map.info.left = left;
        }
      },

      calcLegendIntensity (v) {
        const max = this.map.legend.max;
        // const low = [224,80,64];   // color of mag 1.0
        // const high = [24,100,72];  // color of mag 6.0 and above

        // // fraction represents where the value sits between the min and max
        // const fraction = (v / max);

        // const color = this.interpolateHsl(low, high, fraction);
        const i = v != 0 ? (v/max) : 0;
        return {
          'background-color': i != 0 ? 'rgba(71, 108, 255, ' + ((i * .48) + .32).toString() + ')' : 'rgba(0,0,0,.04)'//color
        };
      },

      initHeatmap () {
        return this.$refs.map.$mapPromise.then((map) => {
          this.map.heatmap.loaded = true;
          this.getBounds();
          return new this.google.maps.visualization.HeatmapLayer({
            data: new this.google.maps.MVCArray(this.heatmapPoints()),
            map: map,
            opacity: .5,
            // maxIntensity: this.heatmapMax,
            radius: this.map.radius,
          });
        });
      },

      updateHeatmap () {
        this.map.heatmap.ref.then((layer) => {
          layer.set('data', new this.google.maps.MVCArray(this.heatmapPoints()));
          // layer.set('maxIntensity', this.heatmapMax);
          console.log(layer.get('maxIntensity'));
        });
      },

      heatmapPoints () {
        // const filter = this.map.filter;
        // console.log(this.heatmap.data[_.find(filter.steps, { doy: filter.interval }).date]);
        return _.map(this.heatmap.data, (point) => {
            return { location: new this.google.maps.LatLng(point.lat, point.lng), weight: point.audience };
          });
      },

      statusLight (buzzer) {
        let color = 'grey';
        if (this.buzzers.alert) {
          const updated = buzzer.updated;
          const tracked = buzzer.impression.updated;
          let diff;
          if (buzzer.status=='ON') {
            if (updated&&tracked) {
              const diffTrack = moment(updated).diff(tracked,'minutes');
              const diffImpression = moment().diff(updated,'minutes'); 
              diff = diffImpression;
              if (diff<=15) {
                color = 'success';
              }else{
                color = 'warning';
              }
            }else{
              color = 'grey';
            }
          }else{
            color = 'error';
          }
        }else{
          color = 'primary';
        }
        return color;
      },

      setBuzzerIcon (buzzer) {
        const url = this.map.icons.buzzer.url.split('.');
        const selected = buzzer.code == this.buzzers.selected;
        const sufix = selected ? 'selected' : this.buzzers.alert ? this.statusLight(buzzer) : 'default';
        const icon = {
          url: url[0] + '-' + sufix + '.' + url[1],
          size: this.map.icons.buzzer.size,
          anchor: this.map.icons.buzzer.anchor,
        };
        return icon;
      },
      
      // highlightBuzzer (buzzer) {
      //   // TODO: Refactor w/o iteration
      //   _.forEach(this.buzzers.data, (item,key) => {
      //     if (item.selected) {
      //       this.buzzers.data[key].selected = false;
      //     }
      //   });
      //   if (buzzer) this.buzzers.data[buzzer].selected = this.buzzers.data[buzzer].loading = true;
      // },

      selectBuzzer (buzzer) {
        if (!this.auditor) {
          this.view.panned = false;
          this.$router.push({
            path: `/buzzers/${buzzer}`
          });
        }
      },

      getBounds () {
        if (this.map.ready) {
          let bounds = new this.google.maps.LatLngBounds();
          // get bound points
          if (this.buzzers.toggle) {
            _.map(this.trackedBuzzers, (buzzer) => {
              const position = buzzer.impression.position;
              bounds.extend(new this.google.maps.LatLng(position.lat, position.lng));
            });
          }
          if (this.heatmap.toggle) {
            _.map(this.heatmapPoints(), (point) => {
              bounds.extend(point.location);
            });
          }
          if (!this.view.panned) {
            if (this.buzzers.selected) {
              const position = this.buzzers.data[this.buzzers.selected].impression.position;
              if (position) this.mapCenter(position);
            }else{
              // this.mapBounds(bounds);
            }
            this.view.panned = false;
          }
        }
      },

      centerBuzzer(position) {
        if (position.lat!==0&&position.lat!==null) this.mapCenter(position, 15);
      },

      mapCenter (position, zoom) {
        this.$refs.map.$mapPromise.then((map) => {
          if (!_.isNil(zoom)) map.setZoom(zoom);
          map.panTo(position);
        });
      },

      mapBounds (layer, features) {
        let bounds;
        this.$refs.map.$mapPromise.then((map) => {
          // map.panToBounds(bounds, this.mapPadding);
          if (layer=='geofences'&&this.geofences.toggle&&_.size(this.geofences.data)>0) {
            bounds = new google.maps.LatLngBounds(); 
            if (_.isNil(features)||_.isEmpty(features)) {
              map.data.forEach(function(feature){
                feature.getGeometry().forEachLatLng(function(latlng){
                  bounds.extend(latlng);
                });
              });
            }else{
              _.each(features, f => {
                if (_.has(this.map[layer].features, f)) {
                  const feature = this.map[layer].features[f];
                  feature.getGeometry().forEachLatLng(function(latlng){
                    bounds.extend(latlng);
                  });
                  map.data.overrideStyle(feature, { 
                    strokeWeight: 2,
                    strokeOpacity: .8,
                  });
                }
              });
              setTimeout((map, layer) => {
                map.data.revertStyle();
                this.$emit('zoom-end', layer);
              }, 3000, map, layer);
            }
          }else if (layer=='outline'&&this.outline.toggle&&_.size(this.outline.data)>0) {
            bounds = new google.maps.LatLngBounds();
            if (_.isNil(features)||_.isEmpty(features)) {
              const selectable = _.has(this.outline, 'selectable') && this.outline.selectable;
              const available = !_.some(this.map[layer].data, ['selected', true]);
              _.each(this.map[layer].data, g => {
                if (available||!selectable||(_.has(g, 'selected')&&g.selected)) this.map[layer].features[g.id].getGeometry().forEachLatLng(function(latlng){
                    bounds.extend(latlng);
                  });
              });
            }else{
              _.each(features, f => {
                if (_.has(this.map[layer].features, f)) {
                  const feature = this.map[layer].features[f];
                  feature.getGeometry().forEachLatLng(function(latlng){
                    bounds.extend(latlng);
                  });
                  map.data.overrideStyle(feature, { 
                    strokeWeight: 2,
                    strokeOpacity: .8,
                  });
                }
              });
              setTimeout((map, layer) => {
                map.data.revertStyle();
                this.$emit('zoom-end', layer);
              }, 3000, map, layer);
            }
          }else if (layer=='pois'&&this.pois.toggle&&_.size(this.pois.data)>0) {
            if (this.pois.point) {
              bounds = new google.maps.LatLngBounds(); 
              if (_.isNil(features)||_.isEmpty(features)) {
                _.each(this.map.pois.points, p => {
                  // console.log('point bounds', p.position);
                  bounds.extend(p.position);
                  const radius = p.drawing.getBounds();
                  bounds.extend(radius.getNorthEast());
                  bounds.extend(radius.getSouthWest());
                });
              }else{
                _.each(features, i => {
                  const p = this.map.pois.points[i];
                  console.log('point bounds', p.position);
                  bounds.extend(p.position);
                  const radius = p.drawing.getBounds();
                  bounds.extend(radius.getNorthEast());
                  bounds.extend(radius.getSouthWest());
                });
                setTimeout((map, layer) => {
                  map.data.revertStyle();
                  this.$emit('zoom-end', layer);
                }, 3000, map, layer);
              }
            }else{
              if (_.isNil(features)||_.isEmpty(features)) {
                bounds = new google.maps.LatLngBounds(); 
                map.data.forEach(function(feature){
                  feature.getGeometry().forEachLatLng(function(latlng){
                    bounds.extend(latlng);
                  });
                });
              }else{
                _.each(features, f => {
                  if (_.has(this.map[layer].features, f)) {
                    const feature = this.map[layer].features[f];
                    feature.getGeometry().forEachLatLng(function(latlng){
                      bounds.extend(latlng);
                    });
                    map.data.overrideStyle(feature, { 
                      strokeWeight: 2,
                      strokeOpacity: .8,
                    });
                  }
                });
                setTimeout((map, layer) => {
                  map.data.revertStyle();
                  this.$emit('zoom-end', layer);
                }, 3000, map, layer);
              }

            }
          }
          if (!_.isNil(bounds)&&!bounds.isEmpty()) map.fitBounds(bounds, this.map.padding);
        });
      },

      // processGeojson (geometry, callback, thisArg) {
      //   if (geometry instanceof google.maps.LatLng) {
      //     callback.call(thisArg, geometry);
      //   } else if (geometry instanceof google.maps.Data.Point) {
      //     callback.call(thisArg, geometry.get());
      //   } else {
      //     geometry.getArray().forEach(function(g) {
      //       processPoints(g, callback, thisArg);
      //     });
      //   }
      // },


      filterInterval (data) {
        let steps = _.map(_.keys(data), (key) => {
          return {
            doy: moment(key, 'DD/MM/YY').dayOfYear(),
            date: key
          }
        }).sort();
        this.map.filter.steps = steps;
        this.map.filter.label = steps[steps.length-1].date;
        this.map.filter.max = steps[steps.length-1].doy;
        this.map.filter.min = steps[0].doy;
        this.map.filter.interval = this.map.filter.max;
      },

      matchKey (k, item) {
        const key = new RegExp(k, 'gi');
        return key.test(item.code) || key.test(item.mode) || key.test(item.status) || key.test(item.driver.name);
      },

      mapAvailable () {
        if (_.has(this.$refs, 'map')) {
          if (_.indexOf(this.layers, 'geofences')>=0||_.indexOf(this.layers, 'outline')>=0) {
            if (_.size(this.map.geofences.source.BAI)==0) {
              setTimeout(this.mapAvailable, 500);
            }else{
              console.log('map ready');
              this.map.ready = true;
              if (this.geofences.toggle&&_.size(this.geofences.data)>0) {
                this.initGeofenceLayer();
              }else if (this.pois.toggle&&_.size(this.pois.data)>0) {
                this.initPoiLayer();
              }else if (this.outline.toggle&&_.size(this.outline.data)>0) {
                this.initOutlineLayer();
              }
            }
          }else{
            console.log('map ready');
            this.map.ready = true;
          }
        }else{
          setTimeout(this.mapAvailable, 500);
        }
      }

    },

    async mounted () {
      // this.map.ref = this.$refs.map.$mapObject;
      await this.$gmapApiPromiseLazy().then(() => {
        this.mapAvailable();
      });
      this.view.panned = false;

      // load geojson
      bai().then((data) => {
        this.map.geofences.source['BAI'] = _.mapValues(_.groupBy(data.geofences, (d) => {
          return d.id;
        }), g => g[0]);
        this.$emit('geofences-loaded', data.geofences);
      })
      zon().then((data) => {
        const zones = this.map.geofences.source['ZON'] = {...data.geofences[0]};
        this.$emit('zones-loaded', zones);
      })
    },

  }

</script>
